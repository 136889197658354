import { Link } from 'gatsby'
import React from 'react'
import PropTypes from 'prop-types'

const NewsArticleMeta = ({ date, tags }) => {
  const keyword = tags[0]
  return (
    <ul className="articleMeta">
      <li>
        <Link to={'tags/' + keyword} style={{ textTransform: 'uppercase' }}>
          {keyword}
        </Link>
      </li>
      <li>
        <p>{' | ' + date}</p>
      </li>
    </ul>
  )
}

NewsArticleMeta.propTypes = {
  date: PropTypes.string.isRequired,
  tags: PropTypes.array,
}

export default NewsArticleMeta
