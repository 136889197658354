import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Hero from '../components/Hero'
import Helmet from 'react-helmet'
import { withIntl } from '../i18n'

import StandardNews from '../components/News/StandardNews'
import LargeNews from '../components/News/LargeNews'
import MediumNews from '../components/News/MediumNews'

const NewsPageTemplate = ({
  title,
  posts,
  seoDescription,
  featuredArticles,
  featuredSecondaryArticles,
}) => (
  <div id="news">
    <Helmet>
      <meta charSet="utf-8" />
      <title>{'Kristiania Management - ' + title}</title>
      <meta name="description" content={seoDescription} />
    </Helmet>
    <Hero title={title ? title : 'Aktuelt'} />
    <section className="section has-background-primary">
      <div className="container">
        {featuredArticles && featuredArticles.length > 0
          ? featuredArticles.map(({ article: post }, index) => (
              <LargeNews
                key={index}
                title={post.frontmatter.articleTitle}
                date={post.frontmatter.date}
                slug={post.fields.slug}
                img={post.frontmatter.img}
                tags={post.frontmatter.tags}
              />
            ))
          : ''}
        <div className="wrapper threeCol">
          {featuredSecondaryArticles && featuredSecondaryArticles.length > 0
            ? featuredSecondaryArticles.map(({ article: post }, index) => (
                <MediumNews
                  key={index}
                  title={post.frontmatter.articleTitle}
                  date={post.frontmatter.date}
                  slug={post.fields.slug}
                  img={post.frontmatter.img}
                  tags={post.frontmatter.tags}
                />
              ))
            : ''}
        </div>
        <div className="wrapper">
          {posts && posts.length > 0
            ? posts.map(({ node: post }, index) => (
                <StandardNews
                  key={index}
                  title={post.frontmatter.articleTitle}
                  date={post.frontmatter.date}
                  slug={post.fields.slug}
                  img={post.frontmatter.img}
                  tags={post.frontmatter.tags}
                />
              ))
            : ''}
        </div>
      </div>
    </section>
  </div>
)

NewsPageTemplate.propTypes = {
  title: PropTypes.string,
  seoDescription: PropTypes.string,
  posts: PropTypes.array,
  featuredArticles: PropTypes.array,
  featuredSecondaryArticles: PropTypes.array,
}

export class NewsPage extends React.Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data ? data.articles : []
    let indexFrontMatter = {}
    if (data && data.index) {
      indexFrontMatter = data.index.frontmatter
    }

    return (
      <Layout>
        <NewsPageTemplate
          title={indexFrontMatter.title}
          posts={posts}
          seoDescription={indexFrontMatter.seoDescription}
          featuredArticles={indexFrontMatter.featuredArticles}
          featuredSecondaryArticles={indexFrontMatter.featuredSecondaryArticles}
        />
      </Layout>
    )
  }
}

export default withIntl(NewsPage)

NewsPage.propTypes = {
  data: PropTypes.shape({
    articles: PropTypes.shape({
      edges: PropTypes.array,
    }),
    index: PropTypes.shape({
      edges: PropTypes.array,
      frontmatter: PropTypes.object,
    }),
    markdownRemark: PropTypes.object,
  }),
}

export const pageQuery = graphql`
  query nyheterQuery($id: String!, $locale: String!) {
    articles: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: {
        frontmatter: { templateKey: { eq: "blog-post" }, i18n: { eq: $locale } }
      }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            articleTitle
            templateKey
            date(formatString: "D MMMM", locale: "nb")
            img
            tags
          }
        }
      }
    }
    index: markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        seoDescription
        featuredArticles {
          article {
            id
            fields {
              slug
            }
            frontmatter {
              articleTitle
              date(formatString: "D MMMM", locale: $locale)
              img
              tags
            }
          }
        }
        featuredSecondaryArticles {
          article {
            id
            fields {
              slug
            }
            frontmatter {
              articleTitle
              date(formatString: "D MMMM", locale: "nb")
              img
              tags
            }
          }
        }
      }
    }
  }
`
