import { Link } from 'gatsby'
import React from 'react'
import PropTypes from 'prop-types'
import NewsArticleMeta from './NewsArticleMeta'

const StandardNews = ({ title, date, slug, img, tags }) => (
  <div className="box">
    <Link to={slug}>
      <figure className="image">
        <img
          src={
            img ? img : 'https://via.placeholder.com/150/192230/FFFFFF?Text=HEI'
          }
          alt={title}
        />
      </figure>
    </Link>
    <NewsArticleMeta date={date} tags={tags} />
    <Link to={slug}>
      <h3 className="subtitle">{title}</h3>
    </Link>
  </div>
)

StandardNews.propTypes = {
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  slug: PropTypes.string,
  img: PropTypes.string,
  index: PropTypes.number,
  tags: PropTypes.array,
}

export default StandardNews
