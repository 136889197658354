import React from 'react'
import PropTypes from 'prop-types'
import logo from '../img/logo.svg'

const Hero = ({ title }) => (
  <section className="hero hero-brand is-primary">
    <div className="hero-body">
      <figure className="image is-hidden-mobile">
        <img src={logo} alt="Kristiania Management" />
      </figure>
      <h1>{title}</h1>
    </div>
  </section>
)

Hero.propTypes = {
  title: PropTypes.string,
}

export default Hero

// </form>
