import { Link } from 'gatsby'
import React from 'react'
import PropTypes from 'prop-types'

const LargeNews = ({ title, date, slug, img, tags }) => {
  const keyword = tags[0]
  return (
    <div className="large-article">
      <Link to={slug}>
        <figure className="image">
          <img
            src={
              img
                ? img
                : 'https://via.placeholder.com/150/192230/FFFFFF?Text=HEI'
            }
            alt={title}
          />
        </figure>
      </Link>
      <div className="columns">
        <div className="column is-four-fifths articleMeta">
          <ul className="articleMeta">
            <li>
              <Link
                to={'tags/' + keyword}
                style={{ textTransform: 'uppercase' }}
              >
                {keyword}
              </Link>
            </li>
            <li>
              {' '}
              <p className="">{' | ' + date}</p>
            </li>
          </ul>

          <Link to={slug}>
            <h3 className="subtitle">{title}</h3>
          </Link>
        </div>
        <div className="column">
          <Link className="button is-outlined is-small" to={slug}>
            Les mer →
          </Link>
        </div>
      </div>
    </div>
  )
}

LargeNews.propTypes = {
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  slug: PropTypes.string,
  img: PropTypes.string,
  index: PropTypes.number,
  tags: PropTypes.array,
}

export default LargeNews
